import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

export default class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ga: 10,
      gb: 4,
      ma: 10,
      mb: 4,
    }

    this.handleClickG = this.handleClickG.bind(this)
    this.handleClickM = this.handleClickM.bind(this)
  }

  handleClickG(event) {
    event.preventDefault();

    Object.values(this.refs).forEach(field => {
      this.setState({
        [field.name]: !isNaN(field.value) ? field.value : 0
      });
    });
  }

  handleClickM(event) {
    event.preventDefault();

    Object.values(this.refs).forEach(field => {
      this.setState({
        [field.name]: !isNaN(field.value) ? field.value : 0
      });
    });
  }

  renderGeDiv() {
    const a = this.state.ga
    const b = this.state.gb
    const c = a / b
    const cString = c.toString().replace(".", ",")
    const d = Math.floor(a / b)

    if (d >= 0) {
      return (
        <>
          Lösungsschritt 1) Normale Division durchführen:{" "}
          <span className="text-danger">{a}</span> /{" "}
          <span className="text-primary">{b}</span> ={" "}
          <span className="text-success">{cString}</span>
          <br />
          Lösungsschritt 2) Nächstkleinere ganze Zahl finden, falls das
          Ergebnis keine ganze Zahl ist:
          <br />
          aus <span className="text-success">{cString}</span> wird{" "}
          <span className="text-warning">{d}</span>.<br />
          Lösung) Die <span className="text-primary">{b}</span> passt{" "}
          <span className="text-warning">{d}</span> mal vollständig in die{" "}
          <span className="text-danger">{a}</span>. Daher ist{" "}
          <span className="text-danger">{a}</span> div{" "}
          <span className="text-primary">{b}</span> ={" "}
          <span className="text-warning">{d}</span>.
        </>
      )
    } else {
      return (
        <>
          Lösungsschritt 1) Normale Division durchführen:{" "}
          <span className="text-danger">{a}</span> /{" "}
          <span className="text-primary">{b}</span> ={" "}
          <span className="text-success">{cString}</span>
          <br />
          Lösungsschritt 2) Nächstkleinere ganze Zahl finden, falls das
          Ergebnis keine ganze Zahl ist:
          <br />
          aus <span className="text-success">{cString}</span> wird{" "}
          <span className="text-warning">{d}</span>.<br />
          Lösung) <span className="text-danger">{a}</span> div{" "}
          <span className="text-primary">{b}</span> ={" "}
          <span className="text-warning">{d}</span>.
        </>
      )
    }
  }

  renderMeDiv() {

    const a = this.state.ma
    const b = this.state.mb
    const d = Math.floor(a / b)
    const m = a % b

    return (
      <>
        Lösungsschritt 1) Ganzzahldivision durchführen:{" "}
        <span className="text-danger">{a}</span> div{" "}
        <span className="text-primary">{b}</span> ={" "}
        <span className="text-warning">{d}</span><br />
        Lösungsschritt 2) Rest berechnen:{" "}
        <span className="text-danger">{a}</span> - (
        <span className="text-warning">{d}</span> *{" "}
        <span className="text-primary">{b}</span>) ={" "}
        <span className="text-success">{m}</span><br />
        Lösung){" "}
        <span className="text-danger">{a}</span> mod{" "}
        <span className="text-primary">{b}</span> ={" "}
        <span className="text-success">{m}</span>
      </>
    )
  }

  render() {
    const divStyle = {
      borderColor: "#222222",
      marginLeft: "0px",
      marginTop: "0px",
      borderWidth: "1px",
      borderStyle: "solid",
      padding: "4px",
    }

    return (
      <Layout title="Division mit Rest">
        <SEO title="Division mit Rest" />
        <h2>Die Ganzzahldivision mit dem Operator div</h2>
        <p>
          Bei der Ganzzahldivision mit positiven Zahlen wird ermittelt, wie oft
          der rechte Operand vollständig in den linken Operanden hinein passt.
        </p>
        <p>
            <input
                ref="ga"
                aria-label="ga"
                type="text"
                name="ga"
                defaultValue={this.state.ga}
                size="3"
                className="text-danger"
            />
            &nbsp;div&nbsp;
            <input
                ref="gb"
                aria-label="gb"
                type="text"
                name="gb"
                defaultValue={this.state.gb}
                size="3"
                className="text-primary"
            />
            &nbsp;
            <button
                className="btn btn-primary"
                onClick={this.handleClickG}
            >Berechnen
            </button>
        </p>
        <div id="field-ge" style={divStyle}>
          {this.renderGeDiv()}
        </div>
        <br />
        <p>
          <b>Aufgabe 1)</b>
          <br />
          Berechne die Ergebnisse folgender Ganzzahldivisionen auf einem Zettel
          und kontrolliere die Lösungen:
          <br />
          a) 7 div 3, b) 8 div 3, c) 15 div 4,
          d) 2 div 4, e) -3 div 2, f) -2 div 3,
          g) -7 div 3
        </p>
        <h2>Rest einer Ganzzahldivision mit dem Operator mod</h2>
        <p>
          Das Ergebnis der Modulo-Operation ist der verbleibende Restwert der
          Ganzzahldivision.
        </p>
        <p>
            <input
                ref="ma"
                aria-label="ma"
                type="text"
                name="ma"
                defaultValue={this.state.ma}
                size="3"
                className="text-danger"
            />
            &nbsp;mod&nbsp;
            <input
                ref="mb"
                aria-label="mb"
                type="text"
                name="mb"
                defaultValue={this.state.mb}
                size="3"
                className="text-primary"
            />
            &nbsp;
            <button
                className="btn btn-primary"
                onClick={this.handleClickM}
            >Berechnen
            </button>
        </p>
        <div style={divStyle}>{this.renderMeDiv()}</div>
        <br />
        <p>
          <b>Aufgabe 2)</b><br />
          Berechne die Ergebnisse folgender Aufgaben auf einem Zettel und
          kontrolliere die Lösungen:<br />
          a) 7 mod 3, b) 8 mod 3, c) 15 mod 4,
          d) 2 mod 4, e) -3 mod 2, f) -2 mod 3, g) -7 mod 3<br /><br />
          <b>Aufgabe 3)</b><br />
          Wie kann mit Hilfe einer Modulo-Operation entschieden werden, ob eine
          Zahl gerade oder ungerade ist?<br /><br />
          <b>Aufgabe 4)</b><br />
          Es ist 15 Uhr. Wie spät ist es in 300 Stunden?<br /><br />
          <b>Aufgabe 5)</b><br />
          Wieso ist das Ergebnis einer Modulo-Operation niemals größer oder
          gleich dem rechten Operanden?
        </p>
      </Layout>
    )
  }
}